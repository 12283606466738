<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="PROJECTS"
          :breadcrumb="[
            {
              label: 'professional',
            },
            { label: 'Project View' },
          ]"
        >
        </banner>
        <div class="row" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <!-- pc responsive -->
            <div
              class="card custom-card d-none d-md-block d-xl-block"
              style="font-family: 'Poppins', sans-serif; "
            >
              <div class="card-body">
                <h4
                  style="
                    color: #00364f;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                  "
                >
                  <b
                    v-if="project.service"
                    style="
                      color: #00364f;
                      font-family: 'Poppins', sans-serif;
                      font-size: 1.2rem;
                    "
                  >
                    {{ project.service.name }}</b
                  >
                </h4>

                <strong> Modules: </strong>
                <span
                  style="text-transform: uppercase"
                  v-for="(module1, index) in serviceModules"
                  :key="index"
                  >{{ module1.moduleName }}, &nbsp;</span
                >
                <div class="row" style="margin-left: 0px; margin-right: 0px">
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Code</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ project.clientServiceNo }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Project Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ dateTime(project.date) }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Phone No.</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.phone }}</strong></span
                    >
                  </div>

                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Email</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.email }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>&nbsp;&nbsp;Due Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ dateTime(project.dueDate) }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div>

                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Status </b>
                    </h4>

                    <p
                      v-if="project.status == 'pending'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: gray;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>New</b>
                    </p>
                    <p
                      v-if="project.status == 'assigned'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #349c9e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'completed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #3ab04b;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'processed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ffaa00;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'hold'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #8f8d8e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'rejected'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: black;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'cancelled'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #f50303;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'upgrade'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ee0def;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- mobile responsive -->
            <div
              class="card custom-card d-block d-sm-block d-xl-none d-md-none"
              style="font-family: 'Poppins', sans-serif; "
            >
              <div class="card-body">
                <h4
                  style="
                    color: #00364f;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                  "
                >
                  <b
                    v-if="project.service"
                    style="
                      color: #00364f;
                      font-family: 'Poppins', sans-serif;
                      font-size: 1.2rem;
                    "
                  >
                    {{ project.service.name }}</b
                  >
                </h4>

                <strong> Modules: </strong>
                <span
                  style="text-transform: uppercase"
                  v-for="(module1, index) in serviceModules"
                  :key="index"
                  >{{ module1.moduleName }}, &nbsp;</span
                >
                <div class="row mt-1">
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Code</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ project.clientServiceNo }}</strong></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Project Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{ dateTime(project.date) }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Phone No.</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.phone }}</strong></span
                    >
                  </div>

                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Email</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.email }}</strong></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>&nbsp;&nbsp;Due Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{ dateTime(project.dueDate) }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share </b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Status </b>
                    </h4>

                    <p
                      v-if="project.status == 'pending'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: gray;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>New</b>
                    </p>
                    <p
                      v-if="project.status == 'assigned'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #349c9e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'completed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #3ab04b;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'processed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ffaa00;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'hold'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #8f8d8e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'rejected'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: black;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'cancelled'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #f50303;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'upgrade'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ee0def;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- end resposnive -->
          </div>
        </div>

        <div class="row" style="margin-top: -17.5px">
          <div class="col-xl-7 col-md-7 col-sm-7" style="padding-right: 8px">
            <div
              class="card custom-card"
              style="height: 100%;"
            >
              <div class="card-body pt-2">
                <h3 style="color: black">
                  <b>Message & Notification History</b>
                </h3>
                <div style="overflow-y: auto; height: 400px; overflow-x: none">
                  <div
                    v-for="(comment, index) in comments"
                    :key="index"
                    class="row mb-1 radius"
                    style="
                      background-color: #f5f5f5;

                      border-radius: 10px;
                      padding: 10px 10px 10px 10px;
                    "
                  >
                    <div class="col-xl-12">
                      <img
                        style="width: 30; height: 30px; border-radius: 100%"
                        :src="findImgUsedr(comment.user, comment.user_type)"
                        alt=""
                      />
                      &nbsp; &nbsp;
                      <b style="color: green" v-if="comment.user.role_id == 1">{{
                        comment.user.name
                      }}   ( {{ comment.user_type}})</b>
                      <b style="color: #0170b9" v-if="comment.user.role_id == 2">{{
                        comment.user.name
                      }}   ( {{ comment.user_type}})</b>
                      <b style="color: #f21000" v-if="comment.user.role_id == 3">{{
                        comment.user.name
                      }}   ( {{ comment.user_type}})</b>
                      <b style="color: #af05f2" v-if="comment.user.role_id == 7">{{
                        comment.user.name
                      }}   ( {{ comment.user_type}})</b>
                      <b style="color: #ed731c" v-if="comment.user.role_id == 8">{{
                        comment.user.name
                      }}


                      ( {{ comment.user_type}})
                      </b>
                      &nbsp;
                    

                      <small style="color: #4c4c4c; text-transform: uppercase">
                        {{ comment.time }}, {{   dateTime(comment.date) }}</small
                      >

                      <p
                        style="
                          color: 000000;
                          font-size: 14px;
                          padding: 10px 10px 10px 10px;
                          text-align: justify;
                        "
                      >
                        {{ comment.comment }}
                      </p>
                    </div>
                  </div>
                </div>

                <form @submit.prevent="sandMessagePublick('public')">
                  <div class="row mt-1 mb-1">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <textarea
                      required
                        v-model="msgFromPublic.comment"
                        id=""
                        rows="4"
                        class="form-control"
                        placeholder="Type your Messege here"
                      ></textarea>
                      <div class="text-end">
                        <button
                          type="submit"
                          class="btn btn-sm mt-1 text-white"
                          style="background-color: #00364f; padding: 7px 50px 7px 50px"
                        >
                          <b>send</b>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <h4 style="color: black">
                  <strong>Private Messeges</strong>
                </h4>
                <!-- <div class="form-check" v-if="project.professional">
                  <input
                    @click="privateTypeSand('professional')"
                    type="radio"
                    name="flexRadioDefault"
                    id="professionalp"
                  />
                  <label
                    @click="privateTypeSand('professional')"
                    class="form-check-label"
                    for="professionalp"
                  >
                    Professional
                  </label>
                </div> -->

                <div class="form-check" style="margin:5px 5px 5px -20px">
                <!--   <input
                    @click="privateTypeSand('retailer')"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked
                  />
                  <label
                    @click="privateTypeSand('retailer')"
                    class="form-check-label"
                    for="flexRadioDefault2"
                  >
                    Retailer
                  </label>
                </div>
                <div class="form-check" v-if="project.staff">
                  <input
                    @click="privateTypeSand('staff')"
                    type="radio"
                    name="flexRadioDefault"
                    id="staffS"
                    checked
                  />
                  <label
                    @click="privateTypeSand('staff')"
                    class="form-check-label"
                    for="staffS"
                  >
                    Staff
                  </label> -->




          <div class="row">
            <div class="col-xl-12 col-md-12 col-12-12">
         


<select v-model="userValue"   @change="privateTypeSand" class="form-select" aria-label="Default select example">
  <option selected disabled>Select...</option>
  <option v-if="project.staff" :value="project.staff">{{project.staff.code}} {{project.staff.name}} (Staff)</option>
  <option  :value="1">SUP0001 SuperAdmin (Super Admin)</option>
  

</select>
            </div>
          </div>


                </div>
                <div style="overflow-y: auto; height: 400px; overflow-x: none">
                  <div
                    v-for="(comment1, index) in commentsprivates"
                    :key="index"
                    class="row mb-1 radius"
                    style="
                      background-color: #f5f5f5;
                      border-radius: 10px;
                      padding: 10px 10px 10px 10px;
                    "
                  >
                    <!-- <div class="col-xl-1 col-2">
                    
                    
                  </div> -->

                    <div class="col-xl-12">
                      <!-- <a
                        href="#replyCommmentHere"
                        style="text-decoreation: none; color: #00364f"
                      >
                        <div class="text-end" @click="replyData(comment1)">
                          <i class="fa fa-reply-all" aria-hidden="true"></i>
                        </div>
                      </a> -->
                      <img
                        style="width: 30; height: 30px; border-radius: 100%"
                        :src="findImgUsedr(comment1.user, comment1.user_type)"
                        alt=""
                      />
                      &nbsp; &nbsp;
                      <b style="color: green" v-if="comment1.user.role_id == 1">{{
                        comment1.user.name
                      }} ( {{ comment1.user_type}})</b>

                      <b style="color: #0170b9" v-if="comment1.user.role_id == 2">{{
                        comment1.user.name
                      }}   ( {{ comment1.user_type}})</b>
                      <b style="color: #f21000" v-if="comment1.user.role_id == 3">{{
                        comment1.user.name
                      }}   ( {{ comment1.user_type}})</b>
                      <b style="color: #ed731c" v-if="comment1.user.role_id == 8">{{
                        comment1.user.name
                      }}   ( {{ comment1.user_type}})</b>
                      &nbsp;

                      <small style="color: #4c4c4c; text-transform: uppercase">
                        {{ comment1.time }}, {{   dateTime(comment1.date) }}</small
                      >

                      <p
                        style="
                          color: 000000;
                          font-size: 14px;
                          padding: 10px 10px 10px 10px;
                          text-align: justify;
                        "
                      >
                        {{ comment1.comment }}
                       
                      </p>
                    </div>
                  </div>
                </div>

                <form @submit.prevent="sandMessagePublick('private')">
                  <div class="row mt-1 mb-1">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <!-- <div
                        v-if="replyBox == 'true'"
                        class="replyData p-1"
                        id="replyData"
                        style="
                          background-color: gray;
                          color: white;
                          border-radius: 5px 5px 0px 0px;
                          font-size: 10px;
                        "
                      >
                        <strong style="color: #f21000">You: </strong>
                        <br />
                        <span id="ReplyContent">
                          {{ replyDataComment }}
                        </span>
                      </div> -->
                      <textarea
                      required
                        v-model="msgFromPublic.comment"
                        id="replyCommmentHere"
                        rows="4"
                        class="form-control"
                        placeholder="Type your Messege here"
                      ></textarea>
                      <div class="text-end">
                        <button
                          type="submit"
                          class="btn btn-sm mt-1 text-white"
                          style="background-color: #00364f; padding: 7px 50px 7px 50px"
                        >
                          <b>send</b>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-md-5 col-sm-5" style="padding-left: 8px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <!-- for Upgrade  -->
            <div
              class="card custom-card"
              style=" padding: 0px 0px 0px 0px"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-9 text-start">
                    <span style="color: #00364f"><strong>Request Upgrade</strong></span>
                  </div>
                  <div class="col-xl-3 text-end">
                    <button
                    @click="formNullUpgrade()"
                      data-bs-toggle="modal"
                      data-bs-target="#upgradeModel"
                      class="btn btn-sm text-white"
                      style="background-color: #00364f; border-radius: 10px"
                    >
                      <strong>Request</strong>
                    </button>
                  </div>

                  <div class="col-12 pt-1">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm" style="font-size: 14px">
                        <tr
                          class="text tr-head table-wrapper-scroll-y rounded-circle"
                          style="background-color: white"
                        >
                          <th
                            class="text-truncate"
                            style="
                              background-color: #cdcdcd;
                              border-radius: 8px 0px 0px 8px;
                            "
                          >
                            Title
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Date
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Amount
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Note
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Remark
                          </th>
                          <th
                            style="background-color: #cdcdcd"
                            class="text-truncate text-center"
                          >
                            Status
                          </th>
                          <th
                            style="
                              background-color: #cdcdcd;
                              border-radius: 0px 8px 8px 0px;
                            "
                            class="text-truncate text-end"
                          >
                            Action
                          </th>
                        </tr>

                        <tbody class="text">
                          <tr v-for="(upgrade, index) in upgradesData" :key="index">
                            <td class="text-truncate">{{ upgrade.title }}</td>
                            <td class="text-truncate">{{ dateTime(upgrade.date) }}</td>
                            <td class="text-truncate">{{ upgrade.amt }}</td>
                            <td class="text-truncate">{{ upgrade.note }}</td>
                            <td class="text-truncate">{{ upgrade.remark }}</td>
                            <td class="text-truncate">
                              <span
                                v-if="upgrade.status == 'approved'"
                                style="
                                  width: 80px;
                                  background-color: #430194;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'paid'"
                                style="
                                  width: 80px;
                                  background-color: green;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'canceled'"
                                style="
                                  width: 80px;
                                  background-color: #f21000;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'pending'"
                                style="
                                  width: 80px;
                                  background-color: orange;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>
                              <span
                                v-if="upgrade.status == 'reject'"
                                style="
                                  width: 80px;
                                  background-color: black;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>
                            </td>
                            <td class="text-truncate">
                              <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="..."
                              >
                                <span
                                  style="
                                    background-color: blue;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                  @click="
                                    openWindow(
                                      `${
                                        this.$store.state.imgUrl +
                                        '/clientservice/' +
                                        this.$route.params.id +
                                        '/' +
                                        upgrade.document
                                      }`
                                    )
                                  "
                                >
                                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                                </span>

                                <span
                                  @click="upgradeDataAsign(upgrade)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#upgradeModel"
                                  style="
                                    background-color: green;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                >
                                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </span>
                                <span
                                  @click="upgradeDelete(upgrade)"
                                  style="
                                    background-color: red;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                >
                                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
          <!-- profesonal area -->
                <!-- for Upgrade  -->
                <!-- :style="(upgradesData.length != 0) ? 'margin-top: -17.5px;' : '' " -->
                <div  v-for="(profesonal,index) in project.client_service_professional" :key="index"   style="margin-top: -17.5px;">
            <div
           v-if="profesonal.professional_id  ==  professionaUser.id"
          :style="(index != 0) ? 'margin-top:-17.5px':''"
           class="card custom-card"
        
         >
           <div class="card-body">
             <div class="row radius" style="line-height: 1.3em; border-radius: 10px">
               <div class="col">
                 <!-- mobile responsive -->
                 <img
                   class="d-xl-none d-md-none d-block"
                   style="
                     width: 100%;
                     height: 100%;
                     margin-left: -10px;
                     border-radius: 100%;
                   "
                   :src="findImgUsedr(profesonal.professional, 'professional')"
                   alt=""
                 />
                 <!-- pc responsive -->
                 <img
                   class="d-xl-block d-md-block d-none"
                   style="
                     width: 80%;
                     height: 85%;
                     border-radius: 100%;
                     margin-left: -10px;
                   "
                   :src="findImgUsedr(profesonal.professional, 'professional')"
                   alt=""
                 />
               </div>
               <div class="col-10">
                 <b style="color: #f21300">Vakilgiri Professional</b>
                 <div class="text">
                   <b>Name : </b>
                   <span v-if="profesonal.professional"> {{ profesonal.professional.name }} ( {{ profesonal.professional.code }})</span>
                 </div>
                 <div class="text">
                   <b>Contact Number : </b>
                   <span v-if="profesonal.professional"> +91- {{ profesonal.professional.phone }} </span>
                 </div>
                 <div class="text">
                   <b>Email : </b>
                   <span v-if="profesonal.professional"> {{ profesonal.professional.email }}</span>
                 </div>

                 <div class="text">
                   <b>status : </b>
                


                  <p v-if="profesonal.status == 'completed'" class='btn btn-sm'  style="background-color: #008000; width: 90px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;">{{ profesonal.status }}</p>
                 
                
                  <p v-if="profesonal.status == 'finalize'" class='btn btn-sm'  style="background-color: #430194; width: 80px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;">{{ profesonal.status }}</p>
                 
                  <p v-if="profesonal.status == 'pending'" class='btn btn-sm'  style="background-color: #cdcdcd; width: 80px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;">{{ profesonal.status }}</p>
                  <p v-if="profesonal.status == 'processed'" class='btn btn-sm'  style="background-color: #ffaa00; width: 80px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;">{{ profesonal.status }}</p>
                  <p v-if="profesonal.status == 'rejected'" class='btn btn-sm'  style="background-color: #000000; width: 80px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;">{{ profesonal.status }}</p>
                  <p v-if="profesonal.status == 'decline'" class='btn btn-sm'  style="background-color: #f21000; width: 80px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;">{{ profesonal.status }}</p>
               
                 
                
                </div>
                 <div class="text" v-if="profesonal.remark">
                   <b>Remark : </b>
                   <span> {{ profesonal.remark }}</span>
                 </div>
               </div>
             </div>
           </div>
         </div>
          </div>
            <!-- end of the professional area  -->

            <div v-if="project.staff" class="card custom-card" style="margin-top: -17.5px;">
              <div class="card-body">
                <div class="row radius" style="line-height: 1.3em; border-radius: 10px">
                  <div class="col">
                    <!-- mobile responsive -->
                    <img
                      class="d-xl-none d-md-none d-block"
                      style="
                        width: 100%;
                        height: 100%;
                        margin-left: -10px;
                        border-radius: 100%;
                      "
                      :src="findImgUsedr(project.staff, 'staff')"
                      alt=""
                    />
                    <!-- pc responsive -->
                    <img
                      class="d-xl-block d-md-block d-none"
                      style="
                        width: 80%;
                        height: 85%;
                        border-radius: 100%;
                        margin-left: -10px;
                      "
                      :src="findImgUsedr(project.staff, 'staff')"
                      alt=""
                    />
                  </div>
                  <div class="col-10">
                    <b style="color: #f21300">Vakilgiri Expert</b>
                    <div class="text">
                      <b>Name : </b>
                      <span v-if="project.staff"> {{ project.staff.name }}</span>
                    </div>
                    <div class="text">
                      <b>Contact Number : </b>
                      <span v-if="project.staff"> +91- {{ project.staff.phone }} </span>
                    </div>
                    <div class="text">
                      <b>Email : </b>
                      <span v-if="project.staff"> {{ project.staff.email }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

       
            <div
            v-if="Tasks.length != 0"
              class="card custom-card"
              style="margin-top: -17.5px;"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-3 col-md-3 col-sm-3">
                    <b
                      class="font"
                      style="color: black; font-weight: 600; font-size: 16px"
                      >Tasks</b
                    >
                  </div>
                  <div class="col-xl-9 col-md-9 col-sm-9">
                    <div class="progress" v-if="taskProgShow == 'false'">
                      <div
                        id="progressBarProject"
                        class="progress-bar bg-danger"
                        role="progressbar"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        v-if="Tasks.length != 0"
                        :style="`width: ${this.progressForProject()}%;`"
                      >
                        {{ taskProgress }}%
                      </div>
                    </div>

                    <div class="progress" v-else>
                      <div
                        id="progressBarProject"
                        class="progress-bar bg-danger"
                        role="progressbar"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        v-if="Tasks.length != 0"
                        :style="`width: ${this.taskProgress}%;`"
                      >
                        {{ taskProgress }}%
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div>
                      <div
                        class="form-check mt-1"
                        v-for="(task, index) in Tasks"
                        :key="index"
                      >
                        <input
                          v-if="task.status == 'complete'"
                          class="form-check-input"
                          @click="saveTask($event, task)"
                          type="checkbox"
                          checked
                        />
                        <input
                          v-else
                          class="form-check-input"
                          @click="saveTask($event, task)"
                          type="checkbox"
                        />
                        <label style="color: #00364f; font-weight: 600">
                          <span v-if="task.step"> {{ task.step.title }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="card custom-card"
              style="margin-top: -17.5px; "
            >
              <div class="card-body font">
                <h4>
                  <b style="color: black">Request Documents from Client</b>
                </h4>
                <form @submit.prevent="clientServiceDoccumentRequestPost()">
                  <div class="row">
                    <div
                      class="col-xl-12 col-md-12 col-sm-12 font"
                      style="padding: 5px 5px 5px 5px"
                      v-if="project.service"
                    >
                      <select
                      required
                        v-model="clientDoccumentsfrom.title"
                        class="form-select  text"
                     
                      >
                        <option selected disabled>Open this select menu</option>
                        <option
                          :value="docc.document"
                          v-for="(docc, index) in project.service.service_document"
                          :key="index"
                        >
                          {{ docc.document }}
                        </option>
                      </select>
                    </div>
                    <div class="col-xl-12 col-sm-12 col-md-12"   style="padding: 5px 5px 5px 5px">
                      <textarea placeholder="Remark" name="" id="" v-model="clientDoccumentsfrom.description" rows="2" class="form-control"></textarea>
                    </div>
                    <div class="col-xl-12 col-sm-12 col-md-12"   style="padding: 5px 5px 5px 5px">
                      <button
                        type="submit"
                        class="btn text-white btns form-control"
                        style="border-radius: 10px"
                      >
                        Request
                      </button>
                    </div>
                  </div>
                </form>
                <div class="row mt-2 text">
                



                  <div class="col-12 pt-1">
                    <h4><b style="color: black">Requested Documents</b></h4>
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm" style="font-size: 14px">
                        <tr
                          class="text tr-head table-wrapper-scroll-y rounded-circle"
                          style="background-color: white"
                        >
                          <th
                            class="text-truncate"
                            style="
                              background-color: #cdcdcd;
                              border-radius: 8px 0px 0px 8px;
                            "
                          >
                            +
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Title
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Doccument
                          </th>
                        

                          <th
                            style="
                              background-color: #cdcdcd;
                              border-radius: 0px 8px 8px 0px;
                            "
                            class="text-truncate text-end"
                          >
                          Remark
                          </th>
                        </tr>

                        <tbody class="text">
                          <tr v-for="(clentDocc, index) in requestdocuments" :key="index">
                            <td class="text-truncate">
                              <span v-if="clentDocc.status == 'complete'">
                                <i
                          class="fa fa-check"
                          aria-hidden="true"
                          style="color: green"
                        ></i>
                              </span>
                              <span v-else>
                                <font-awesome-icon icon="remove" style="color: red" />
                              </span>
                            
                            </td>
                            <td class="text-truncate">{{ clentDocc.title }}</td>
                            <td class="text-truncate">
                              <span v-if="clentDocc.status == 'complete'">
                              
                                
                                <span @click="openWindowClientDoccRequest(clentDocc)"
                          ><i class="fa fa-download" aria-hidden="true"></i></span
                      >


                              </span>
                            </td>
                            <td class="text-truncate">{{ clentDocc.description }}</td>
                           
                          
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="documentsByRetailerAll.length != 0"
              class="card custom-card"
            
            >
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                  margin-top: -17.5px;
                "
              >
                <div class="col-md-8 text-white">
                  <h4><b class="text-white">DOCS UPLOADED BY RETAILER</b></h4>
                </div>
                <div class="col-md-4 text-end">
                  <!-- <button
                    type="button"
                    class="btn btns text-white"
                    style="
                      background-color: #f21300;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                  >
                    <b>Upload</b>
                  </button> -->
                </div>
              </div>
              <div class="card-body pt-1">
                <div >
                  <div
                    class="row"
                    style="padding-top: 3px"
                    v-for="(docc, index) in documentsByRetailerAll"
                    :key="index"
                  >
                    <div class="col-xl-4 col-md-4 col-6 text">
                      <b v-if="docc.document">{{ docc.caption }}</b>
                    </div>
                    <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                      <!-- <button class="btn" @click="downloadImg(docc)" style="color: red">
                        Download
                      </button> -->

                      <span
                    
                      @click="
                        openWindow(
                          `${
                            this.$store.state.imgUrl +
                            '/clientservice/' +
                            this.$route.params.id +
                            '/' +
                            docc.document
                          }`
                        )
                      "
                      ><b style="color: red; cursor: pointer">Download</b></span
                    >

                    </div>
                    <div class="col-xl-4 col-md-4 col-6 text text-end">
                      <!-- <b class="d-xl-none d-md-none d-block" style="color: red"
                        ><a :href="downloadImg(docc)" style="color: red"> Download </a></b
                      > -->


                      <span
                      class="d-xl-none d-md-none d-block"
                      @click="
                        openWindow(
                          `${
                            this.$store.state.imgUrl +
                            '/clientservice/' +
                            this.$route.params.id +
                            '/' +
                            docc.document
                          }`
                        )
                      "
                      ><b style="color: red; cursor: pointer">Download</b></span
                    >



                      <small>{{dateTimeWithTime(docc.created_at)}}</small>
                    </div>
                    <hr style="margin-top: -1px" />
                  </div>
                </div>
              </div>
            </div>

            <div class="card custom-card">
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                  margin-top: -17.5px;
                "
              >
                <div class="col-md-8 text-white">
                  <h4>
                    <b class="text-white">DOCS UPLOADED BY PROFESSIONAL</b>
                  </h4>
                </div>
                <div class="col-md-4 text-end">
                  <button
                  @click="professionaDoccFormNull()"
                    type="button"
                    class="btn btns text-white"
                    style="
                      background-color: #f21300;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#documentsByPro"
                  >
                    <b>Upload</b>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  style="padding-top: 3px"
                  v-for="(certificate, index) in allDoccs"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text">
                    <b>{{ certificate.title }}</b>
                    <div>
                      <small>
                       {{ certificate.certificateNo }}
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                    <span
                      @click="
                        openWindow(
                          `${
                            this.$store.state.imgUrl +
                            '/clientservice/' +
                            this.$route.params.id +
                            '/' +
                            certificate.certificate
                          }`
                        )
                      "
                      ><b style="color: red; cursor: pointer">Download</b></span
                    >
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <b class="d-xl-none d-md-none d-block" style="color: red">Download</b>
                    <!-- <small
                      >{{ certificate.date }}
                      {{ dateTime(certificate.created_at) }}</small
                    > -->
                    <div v-if="certificate.status == 'pending'" style="color: #f21000;cursor: pointer;">
                          
                    <strong>  <small>    <span   data-bs-toggle="modal"
                      data-bs-target="#documentsByPro" @click="valieAssignCertificate(certificate)">Edit</span> |
                          <span @click="deleteCertificate(certificate)">Delete</span></small></strong>
                          </div>

                    <div>
                    <small
                      >Date: {{ dateTime(certificate.date) }}
                     </small
                    >
                   </div>
<div>
  <small
                      >Exp : {{ dateTime(certificate.ExpireDate) }}
                     </small
                    >
                    </div>


                  </div>
                  <hr style="margin-top: -1px" />
                </div>
              </div>
            </div>

            <div class="card custom-card" >
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                  margin-top: -17.5px;
                "
              >
                <div class="col-md-8 text-white">
                  <h4><b class="text-white">CHALANS</b></h4>
                </div>
                <div class="col-md-4 text-end">
                  <button
                  v-if="clientserviceProfessional.challanStatus != 'finalize'"
                  @click="nullChallanForm()"
                    type="button"
                    class="btn btns text-white"
                    style="
                      background-color: #f21300;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#challansModal"
                  >
                    <b>Upload</b>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  style="padding-top: 2px"
                  v-for="(challan, index) in challans"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text">
                    <b>{{ challan.title }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                    <b class="d-xl-block d-md-block d-none">{{ challan.challanNo }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <small>Amount : Rs.{{ challan.amt }}</small
                    ><br />
                    <small style="color: red; cursor: pointer"
                      ><b
                        ><div >
                          
                        <span     v-if="clientserviceProfessional.challanStatus != 'finalize'"   data-bs-toggle="modal"
                    data-bs-target="#challansModal" @click="valieAssignChallans(challan)">Edit | </span> 
                        <span     v-if="clientserviceProfessional.challanStatus != 'finalize'" @click="deleteChallans(challan)">Delete</span>
                        </div>
                        <span
                          @click="
                            openWindow(
                              `${
                                this.$store.state.imgUrl +
                                '/clientservice/' +
                                this.$route.params.id +
                                '/' +
                                challan.challan
                              }`
                            )
                          "
                          >Download</span
                        >
                        
                        
                      </b></small
                    ><br />
                    <small class="d-xl-none d-md-none d-block">{{
                      challan.challanNo
                    }}</small>
                    <small>{{ dateTime(challan.date) }} </small>
                  </div>
                  <hr />
                </div>
              </div>
              <div
                class="card-footer text-white"
                style="
                  background-color: #00364f;
                  border-radius: 0px 0px 10px 10px;
                  padding: 8px 5px 8px 5px;
                "
              >
                <div class="row">
                  <div class="col-4 text-start"><b>Total Amount</b></div>
                  <div class="col-5 text-start">
                    <button v-if="clientserviceProfessional.challanStatus != 'finalize'" @click="finalSubmitChallan()" class="btn btn-sm " style="background-color: yellow">
                      Final Submit
                    </button>
                  </div>
                  <div class="col-3 text-end">
                    <b>Rs. {{ totalAmtChallan }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- model  -->
  <!-- Button trigger modal -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="documentsByPro"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">DOCS UPLOADED BY PROFESSIONAL</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="msgImgError" class="alert alert-danger p-1" role="alert">
{{msgImgError}}
</div>
          <form @submit.prevent="(certificateEditValue == 'false') ? createCertificateByPrfesional() : updateCertificateByPrfesional() ">
            <div class="row">
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Date</strong> <small style="color: #f21000;">*</small></label>
                <input
                required
                  type="date"
                  class="form-control"
                  v-model="certificateForm.date"
                  placeholder="date"
                />
                <small style="color: red" v-if="certificateForm.errors"
                  ><span v-if="certificateForm.errors.error"
                    ><span v-if="certificateForm.errors.error.date">{{
                      certificateForm.errors.error.date[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Title </strong>  <small style="color: #f21000;">*</small></label>
                <input
                required
                  type="text"
                  class="form-control"
                  v-model="certificateForm.title"
                  placeholder="Title"
                />
                <small style="color: red" v-if="certificateForm.errors"
                  ><span v-if="certificateForm.errors.error"
                    ><span v-if="certificateForm.errors.error.title">{{
                      certificateForm.errors.error.title[0]
                    }}</span></span
                  ></small
                >
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Certificate No</strong> </label>
                <input
                
                  type="text"
                  class="form-control"
                  v-model="certificateForm.certificateNo"
                  placeholder="Certificate No"
                />
                <small style="color: red" v-if="certificateForm.errors"
                  ><span v-if="certificateForm.errors.error"
                    ><span v-if="certificateForm.errors.error.certificateNo">{{
                      certificateForm.errors.error.certificateNo[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>certificate Athority</strong> </label>

                <select
                
                  v-model="certificateForm.certificateAthority"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select</option>
                  <option value="MCA">MCA</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Income Tax">Income Tax</option>
                  <option value="TDS">TDS</option>
                  <option value="IEC Portal">IEC Portal</option>
                  <option value="draft">Draft</option>
                  <option value="NSDL">NSDL</option>
                  <option value="GST Portal">GST Portal</option>
                  <option value="FSSAI">FSSAI</option>
                  <option value="Other">Other</option>
                </select>
                <small style="color: red" v-if="certificateForm.errors"
                  ><span v-if="certificateForm.errors.error"
                    ><span v-if="certificateForm.errors.error.certificateAthority">{{
                      certificateForm.errors.error.certificateAthority[0]
                    }}</span></span
                  ></small
                >
              </div>

              <!-- <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Amount</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.amt"
                  placeholder="Amount"
                />
              </div> -->
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Certificate</strong>  <small style="color: #f21000;">*</small></label>
                <!-- v-model="chalansForm.challan" -->
                <input
                required
                  type="file"
                  class="form-control"
                  @change="UploadCertificate"
                  placeholder="certificate"
                  id="certificate"
                />
                
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Expire Date</strong> 
                  </label>
                <input
              
                  type="date"
                  class="form-control"
                  v-model="certificateForm.expireDate"
                  placeholder="Expire Date"
                />
                <small style="color: red" v-if="certificateForm.errors"
                  ><span v-if="certificateForm.errors.error"
                    ><span v-if="certificateForm.errors.error.expireDate">{{
                      certificateForm.errors.error.expireDate[0]
                    }}</span></span
                  ></small
                >
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Note</strong></label>

                <textarea
                  class="form-control"
                  v-model="certificateForm.note"
                  rows="3"
                  placeholder="Note"
                ></textarea>
                <small style="color: red" v-if="certificateForm.errors"
                  ><span v-if="certificateForm.errors.error"
                    ><span v-if="certificateForm.errors.error.note">{{
                      certificateForm.errors.error.note[0]
                    }}</span></span
                  ></small
                >
              </div>

              <!--
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Validity</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.validity"
                  placeholder="validity"
                />
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="upgradeModel"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong>Upgrade </strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">



<div v-if="msgImgError">
  <div class="alert alert-danger p-1" role="alert">
  {{msgImgError}}
</div>
</div>

          <form
            @submit.prevent="upgradeEdit == 'false' ? createUpgrade() : updateUpgrade()"
          >
            <div class="row">
              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Title</strong> <small style="color: #f21000;">*</small></label>
                <input
                required
                  type="text"
                  class="form-control"
                  v-model="upgradeForm.title"
                  placeholder="Title"
                />
                <small style="color: red" v-if="upgradeForm.errors"
                  ><span v-if="upgradeForm.errors.error"
                    ><span v-if="upgradeForm.errors.error.title">{{
                      upgradeForm.errors.error.title[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Document</strong></label>
                <input
                
                  type="file"
                  class="form-control"
                  @change="uploadUpgrade"
                  placeholder="Title"
                  id="uploadUpgrade"
                />
                
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Date</strong><small style="color: #f21000;">*</small></label>
                <input
                required
                  type="date"
                  class="form-control"
                  v-model="upgradeForm.date"
                  placeholder="date"
                />
                <small style="color: red" v-if="upgradeForm.errors"
                  ><span v-if="upgradeForm.errors.error"
                    ><span v-if="upgradeForm.errors.error.date">{{
                      upgradeForm.errors.error.date[0]
                    }}</span></span
                  ></small
                >
              </div>

              <!-- <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan Athority</strong></label>

                <select
                  v-model="chalansForm.challanAthority"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select</option>
                  <option value="MCA">MCA</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Income Tax">Income Tax</option>
                  <option value="TDS">TDS</option>
                  <option value="IEC Portal">IEC Portal</option>
                  <option value="draft">Draft</option>
                  <option value="NSDL">NSDL</option>
                  <option value="GST Portal">GST Portal</option>
                  <option value="FSSAI">FSSAI</option>
                  <option value="Other">Other</option>
                </select>
              </div> -->

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Amount</strong><small style="color: #f21000;">*</small></label>
                <input
                required
                  type="number"
                  class="form-control"
                  v-model="upgradeForm.amt"
                  min="1"
                  placeholder="Amount"
                />
                <small style="color: red" v-if="upgradeForm.errors"
                  ><span v-if="upgradeForm.errors.error"
                    ><span v-if="upgradeForm.errors.error.amt">{{
                      upgradeForm.errors.error.amt[0]
                    }}</span></span
                  ></small
                >
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Note</strong></label>

                <textarea
                  class="form-control"
                  v-model="upgradeForm.note"
                  rows="3"
                  placeholder="Note"
                ></textarea>
                <small style="color: red" v-if="upgradeForm.errors"
                  ><span v-if="upgradeForm.errors.error"
                    ><span v-if="upgradeForm.errors.error.note">{{
                      upgradeForm.errors.error.note[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="challansModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong>Challan </strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="(challanEditValue == 'false') ? createChalans() : upadteChallans()">
            <div class="row">
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Date</strong><small style="color: #f21000;">*</small></label>
                <input 
                required
                  type="date"
                  class="form-control"
                  v-model="chalansForm.date"
                  placeholder="date"
                />
                <small style="color: red" v-if="chalansForm.errors"
                  ><span v-if="chalansForm.errors.error"
                    ><span v-if="chalansForm.errors.error.date">{{
                      chalansForm.errors.error.date[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Title</strong><small style="color: #f21000;">*</small></label>
                <input
                required
                  type="text"
                  class="form-control"
                  v-model="chalansForm.title"
                  placeholder="Title"
                />
                <small style="color: red" v-if="chalansForm.errors"
                  ><span v-if="chalansForm.errors.error"
                    ><span v-if="chalansForm.errors.error.title">{{
                      chalansForm.errors.error.title[0]
                    }}</span></span
                  ></small
                >
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan No</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.challanNo"
                  placeholder="Challan No"
                />
                <small style="color: red" v-if="chalansForm.errors"
                  ><span v-if="chalansForm.errors.error"
                    ><span v-if="chalansForm.errors.error.challanNo">{{
                      chalansForm.errors.error.challanNo[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan Athority</strong></label>

                <select
                  v-model="chalansForm.challanAthority"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select</option>
                  <option value="MCA">MCA</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Income Tax">Income Tax</option>
                  <option value="TDS">TDS</option>
                  <option value="IEC Portal">IEC Portal</option>
                  <option value="draft">Draft</option>
                  <option value="NSDL">NSDL</option>
                  <option value="GST Portal">GST Portal</option>
                  <option value="FSSAI">FSSAI</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Amount</strong><small style="color: #f21000;">*</small></label>
                <input
                required
                  type="number"
                  min="1"
                  class="form-control"
                  v-model="chalansForm.amt"
                  placeholder="Amount"
                />
                <small style="color: red" v-if="chalansForm.errors"
                  ><span v-if="chalansForm.errors.error"
                    ><span v-if="chalansForm.errors.error.amt">{{
                      chalansForm.errors.error.amt[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan</strong><small style="color: #f21000;">*</small></label>
                <!-- v-model="chalansForm.challan" -->
                <input
              
                required
                  type="file"
                  class="form-control"
                  @change="UploadChallan"
                  placeholder="challan"
                  id="challan"
                />
               
                <small style="color: red" v-if="chalansForm.errors"
                  ><span v-if="chalansForm.errors.error"
                    ><span v-if="chalansForm.errors.error.challan">{{
                      chalansForm.errors.error.challan[0]
                    }}</span></span
                  ></small
                >
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Note</strong></label>

                <textarea
                  class="form-control"
                  v-model="chalansForm.note"
                  rows="3"
                  placeholder="Note"
                ></textarea>
                <small style="color: red" v-if="chalansForm.errors"
                  ><span v-if="chalansForm.errors.error"
                    ><span v-if="chalansForm.errors.error.note">{{
                      chalansForm.errors.error.note[0]
                    }}</span></span
                  ></small
                >
              </div>

              <!-- <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Expire Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="chalansForm.expireDate"
                  placeholder="expireDate"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Validity</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.validity"
                  placeholder="validity"
                />
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading == true">
    <Spinner />
  </div>
</template>

//
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/professional/comman/Spinner.vue";
import Banner from "../../../components/professional/comman/Banner.vue";
import moment from "moment";
// import Form from "vform";
import jQuery from "jquery";
// var _ = require("lodash");
let $ = jQuery;
export default {
  name: "ProfessionalProjectsView",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      serviceModules: [],
      msgFromPublic: {
        client_service_id: null,
        type: "",
        touser_id: null,
        touser_type: "",
        remark: "",
        service_id: null,
        comment: "",
        errors:{}
      },
      requestdocuments: [],
      loading: false,
      challanEditValue: 'false',
      certificateEditValue: 'false',
      challans: [],
      services: [],
      project: {},
      userData: {},
      userValue: {},
      allDoccs: [],

      certificateForm: {
        service_id: null,
        client_service_id: "",
        title: "",
        note: "",
        certificateAthority: "",
        certificateNo: "",
        expireDate: "",
        date: "",
        certificate: "",

        imagePrefix: "certificate",
        errors : {}
      },
      documentsByRetailerAll: [],
      chalansForm: {
        imagePrefix: "challan",
        client_service_id: "",
        service_id: null,

        title: "",
        challanAthority: "",
        challanNo: "",
        date: "",
        expireDate: "",
        validity: "",
        challan: "",
        amt: "",
        note: "",
        remark: "",
        status: "",
        ipAddress: "",
        thumbnail: "yes",
        errors:{}
      },
      challanFile:'',
      clientDoccumentsfrom: {
        title: "",
      },
      professionaUser:{},
      upgradeData: [],
      upgradeForm: {
        imagePrefix: "Upgrade",
        client_service_id: "",
        service_id: null,

        title: "",

        date: "",
        document: "",
        amt: "",
        note: "",
        remark: "",
        status: "",
        ipAddress: "",
        errors:{}
      },
      clientserviceProfessional:{},
      uploadDocc_form: {
        client_service_id: null,
        service_document_id: null,
        alt: "",
        caption: "",
        serviceDocument: "",
        cwidth: "",
        cheight: "",
        cx: "",
        cy: "",
      },
      upgradeEdit: "false",
      upgradesData: [],
      extensionCertificate: "",
      msgImgError: "",
      extensionChallan: "",
      totalAmtChallan: 0,
      TasksData: [],
      Tasks: [],
      taskProgShow: "false",
      comments: [],
      commentsprivates: [],
    };
  },
  methods: {
    formNullUpgrade(){
      this.msgImgError = ""
      this.upgradeForm = {}
      this.upgradeForm.errors = {}
      this.upgradeEdit = 'false'
       document.querySelector("#uploadUpgrade").value = "";
    },
    nullChallanForm(){
      document.querySelector("#challan").value = "";
      this.challanEditValue = 'false'
      this.chalansForm = {}
      // this.nullChallanForm = 'false'
      this.chalansForm = {}
      this.chalansForm.errors = {}
     
    },
    // comment area
    progressForProject() {
   
      var complete = 0;
      for (var i = 0; i < this.Tasks.length; i++) {
        if (this.Tasks[i].status == "complete") {
          complete += 1;
        }
      }
      var n = 100 / this.Tasks.length;

      this.taskProgress = n * complete;

  
      if (this.taskProgShow == "false") {
        return this.taskProgress;
      } else {
        this.taskProgShow = "true";
      }
    },
    changeComments(e) {
      this.msgFromPublic.comment = e.target.value;
      console.log(e.target.value);
    },
    getAllComments() {
      this.loading = true;
      this.$axios
        .get(`professional/clientservicecomment?type=public&client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.comments = res.data.data.data;
        });

      this.$axios
        .get(`professional/clientservicecomment?type=private&client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.commentsprivates = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    privateTypeSand() {
      console.log(this.userValue);
      if (this.userValue == 1) {
        this.msgFromPublic.touser_id = 1;
        this.msgFromPublic.touser_type = "superAdmin";
      }
      if (this.userValue.role_id == 2) {
        this.msgFromPublic.touser_id = this.userValue.id;
        this.msgFromPublic.touser_type = "staff";
      }
      // if (this.userValue.role_id == 8) {
      //   this.msgFromPublic.touser_id = this.userValue.id;
      //   this.msgFromPublic.touser_type = "retailer";
      // }

      console.log(this.msgFromPublic);
    },


    sandMessagePublick(type) {
      // if (type == "private") {
      //   this.msgFromPublic.touser_id = userData.id;
      //   if (userData.role) {
      //     this.msgFromPublic.touser_type = userData.role.name;
      //   }
      // }

      this.msgFromPublic.type = type;
      this.msgFromPublic.client_service_id = this.project.id;
      this.msgFromPublic.service_id = this.project.service_id;
      console.log(this.project);
      console.log(this.msgFromPublic);

      this.loading = true;
      this.$axios
        .post(`professional/clientservicecomment`, this.msgFromPublic, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Comment Sand Successfully", {
            autoClose: 1000,
          });
          this.msgFromPublic = {};
          $("#commentVal").val("");
          // this.msgFromPublic.comment = "";
          this.getAllComments();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response.data.error.service_id[0]);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error.service_id[0],
          });
        });
    },

  
    dateTime(value) {
      
      if(value){
 return moment(value).format("DD-MM-YYYY");
     }
   
   },
   dateTimeWithTime(value) {
     
     if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
     }
     
   },
    openWindow(url) {
      window.open(`${url}`);
    },
    deleteCertificate(value) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`professional/clientservicecertificate/${value.id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.accessToken,
                },
              })
              .then((res) => {
                res;

                toast.success("Certificate Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.LoadDocuments()
              });
          }
        });
    },
    deleteChallans(value) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`professional/clientservicechallan/${value.id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.accessToken,
                },
              })
              .then((res) => {
                res;

                toast.success("Challan Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.getChalans();
              })
              .finally(() => (this.loading = false))
          .catch((error) => {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
          }
        });
    },
    loadTask() {
      this.$axios
        .get(
          `professional/clientserviceprofessionalstep?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.Tasks = res.data.data.data;
        });
    },
    
    loadClientServiceProfesssional() {
      this.$axios
        .get(
          `professional/clientserviceprofessional?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.clientserviceProfessional = res.data.data.data[0];
          console.log('[[[[[[[[==============================]]]]]]]]')
          // console.log(res.data.data.data[0].challanStatus)
        });
    },
    findImgUsedr(user, user_type) {
      var img = "";
      if(user_type == 'superAdmin'){
        user_type = 'superadmin'
      }
      if (user.photo != null) {
        img =
          this.$store.state.imgUrl +
          "/" +
          user_type +
          "/" +
          user.id +
          "/thumbs/" +
          user.photo;
      } else {
        img = this.$store.state.placeholderImg;
      }
      return img;
    },
    saveTask(event, task) {
      var form = {};
      if (event.target.checked == true) {
        console.log(event.target.checked);
        console.log(task);

        form.status = "complete";
        form.remark = "";
      }
      if (event.target.checked == false) {
        form.status = "pending";
        form.remark = "";
      }


      this.loading = true;
      this.$axios
        .post(`professional/clientserviceprofessionalstep/status/${task.id}`, form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);
          this.loadTask()
          this.progressForProject();
          if(form.status == "pending"){
            toast.warning("The Task Pending", {
            autoClose: 1000,
          });
          }else{
            toast.success("The Task successfully Done", {
            autoClose: 1000,
          });
          }
          
          this.getProfessionalTaskCompleted();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        });
    },

    getChalans() {
      var amt = 0;
      this.$axios
        .get(
          `professional/clientservicechallan?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log(res.data);
          this.challans = res.data.data.data;
          for (var i = 0; i < this.challans.length; i++) {
            amt += parseInt(this.challans[i].amt);
          }

          this.totalAmtChallan = amt;
        });
    },
    valieAssignChallans(challan){
      document.querySelector("#challan").value = "";
      this.chalansForm = {}
      this.chalansForm.errors = {}
        this.challanEditValue = 'true'
        this.chalansForm = challan
        
    },
    uploadUpgrade(e) {
      



      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = " File Size Too Big 2MB";
        document.querySelector("#uploadUpgrade").value = ""
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#uploadUpgrade").value;
        this.extensionChallan = fileName.split(".").pop();

        if (
          this.extensionChallan == "pdf" ||
          this.extensionChallan == "jpg" ||
          this.extensionChallan == "jpeg" ||
          this.extensionChallan == "png"  ||
          this.extensionChallan == "PDF" ||
          this.extensionChallan == "JPG" ||
          this.extensionChallan == "JPEG" ||
          this.extensionChallan == "PNG"
        ) {
          
          this.upgradeForm.document = e.target.files["0"];
        } else {
          document.querySelector("#uploadUpgrade").value = ""
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

        

      }
    },
    UploadChallan(e) {
    



      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#challan").value;
        this.extensionChallan = fileName.split(".").pop();

        if (
          this.extensionChallan == "pdf" ||
          this.extensionChallan == "jpg" ||
          this.extensionChallan == "jpeg" ||
          this.extensionChallan == "png"  ||
          this.extensionChallan == "PDF" ||
          this.extensionChallan == "JPG" ||
          this.extensionChallan == "JPEG" ||
          this.extensionChallan == "PNG"
        ) {
          this.chalansForm.challan = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

       
      }
    },
    UploadCertificate(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);

        var fileName;
        fileName = document.querySelector("#certificate").value;
        this.extensionCertificate = fileName.split(".").pop();

        if (
          this.extensionCertificate == "pdf" ||
          this.extensionCertificate == "jpg" ||
          this.extensionCertificate == "jpeg" ||
          this.extensionCertificate == "png"  ||
          this.extensionCertificate == "PDF" ||
          this.extensionCertificate == "JPG" ||
          this.extensionCertificate == "JPEG" ||
          this.extensionCertificate == "PNG"
        ) {
          this.certificateForm.certificate = e.target.files["0"];
        }else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";


        }

        
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
//     UploadCertificate(e) {
      
//       this.msgImgError = "";
//       if (e.target.files["0"].size > 2 * (1024 * 1024)) {
//         e.preventDefault();
//         this.msgImgError = " File Size Too Big 2MB";
//         document.querySelector("#certificate").value = ""
//         return false;
//       } else {
//         var fileName;
//         fileName = document.querySelector("#certificate").value;
//         this.extensionCertificate = fileName.split(".").pop();

//         if (
//           this.extensionCertificate == "pdf" ||
//           this.extensionCertificate == "jpg" ||
//           this.extensionCertificate == "jpeg" ||
//           this.extensionCertificate == "png"  ||
//           this.extensionCertificate == "PDF" ||
//           this.extensionCertificate == "JPG" ||
//           this.extensionCertificate == "JPEG" ||
//           this.extensionCertificate == "PNG"
//         ) {
         
//           this.certificateForm.certificate = e.target.files["0"];
//           document.querySelector("#certificate").value = ""
//         } else {
//           this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";


//         }

//         if(this.msgImgError){
// alert(this.msgImgError)
//         }
//         console.log(this.certificateForm)
        
//       }
//     },
    createChalans() {
      this.chalansForm.client_service_id = this.project.id;
      this.chalansForm.service_id = this.project.service_id;

      // console.log(this.chalansForm);

      const formData = new FormData();
      const keys = Object.keys(this.chalansForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.chalansForm[keys[n]] !== null) {
          formData.append(keys[n], this.chalansForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("professional/clientservicechallan", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          this.chalansForm = {}
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getChalans();
          $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
        
          this.chalansForm.errors = error.response.data
        });
    },
    valieAssignCertificate(certificate){
      this.msgImgError = ""
      document.querySelector("#certificate").value = "";
this.certificateEditValue= 'true'
this.certificateForm = {}
this.certificateForm.errors = {}
this.certificateForm = certificate
this.certificateForm.expireDate = certificate.ExpireDate
    },
    upadteChallans() {
      this.chalansForm.client_service_id = this.project.id;
      this.chalansForm.service_id = this.project.service_id;

      // console.log(this.chalansForm);

      const formData = new FormData();
      const keys = Object.keys(this.chalansForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.chalansForm[keys[n]] !== null) {
          formData.append(keys[n], this.chalansForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post(`professional/clientservicechallan/${this.chalansForm.id}?_method=PUT`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          this.chalansForm = {}
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getChalans();
          $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
        
          this.chalansForm.errors = error.response.data
        });
    },
    openWindowClientDoccRequest(docc) {
      var url = "";
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        this.project.id +
        "/thumbs/" +
        docc.attachment
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          this.project.id +
          "/thumbs/" +
          docc.attachment;
      }

      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        this.project.id +
        "/thumbs/" +
        docc.attachment
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          this.project.id +
          "/" +
          docc.attachment;
      }

      window.open(`${url}`);
    },
    professionaDoccFormNull(){
      this.msgImgError = ""
      this.certificateEditValue = 'false'
      this.certificateForm = {}
      this.certificateForm.errors = {}
      document.querySelector("#certificate").value = ""
    },
    clientServiceDoccumentRequestGet() {
      this.loading = true;
      this.$axios
        .get(`professional/clientservicerequestdocument?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log("*************************");
          // console.log(res.data.data.data);
          this.requestdocuments = res.data.data.data;
          // console.log("*************************");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    clientServiceDoccumentRequestPost() {
      
      this.clientDoccumentsfrom.client_service_id = this.project.id;
      this.clientDoccumentsfrom.service_id = this.project.service_id;

      // console.log(this.chalansForm);

      this.loading = true;
      this.$axios
        .post("professional/clientservicerequestdocument", this.clientDoccumentsfrom, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The Request was sent successfully", {
            autoClose: 1000,
          });
          this.clientDoccumentsfrom = {}
          this.clientServiceDoccumentRequestGet();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // res
 
            this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            text: error.response.data.error,
          });

        });
        this.clientDoccumentsfrom = {}
    },
    getUpgrade() {
      this.loading = true;
      this.$axios
        .get(`professional/clientserviceupgrade?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log("*************************");
          // console.log(res.data.data.data);
          this.upgradesData = res.data.data.data;
          // console.log("*************************");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    createUpgrade() {
      this.upgradeForm.client_service_id = this.project.id;
      this.upgradeForm.service_id = this.project.service_id;

      // console.log(this.upgradeForm);

      const formData = new FormData();
      const keys = Object.keys(this.upgradeForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.upgradeForm[keys[n]] !== null) {
          formData.append(keys[n], this.upgradeForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("professional/clientserviceupgrade", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getUpgrade();
          this.upgradeForm = {};
          $("#upgradeModel").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.upgradeForm.errors = error.response.data
        });
    },
    upgradeDelete(upgrade) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`professional/clientserviceupgrade/${upgrade.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Deleted Succesfully", {
                  autoClose: 1000,
                });
                this.getUpgrade();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                error;
              });
          }
        });
    },

    upgradeDataAsign(upgrade) {
      this.msgImgError  = ""
      this.upgradeForm = {}
      this.upgradeForm.errors = {}
      // console.log(upgrade);
      this.upgradeEdit = "true";
      this.upgradeForm.id = upgrade.id;
      this.upgradeForm.client_service_id = upgrade.client_service_id;
      this.upgradeForm.service_id = upgrade.service_id;
      this.upgradeForm.title = upgrade.title;
      this.upgradeForm.date = upgrade.date;
      this.upgradeForm.amt = upgrade.amt;
      this.upgradeForm.note = upgrade.note;
      // console.log(this.upgradeForm);
    },
    updateUpgrade() {
      this.upgradeForm.client_service_id = this.project.id;
      this.upgradeForm.service_id = this.project.service_id;

      // console.log(this.upgradeForm);

      const formData = new FormData();
      const keys = Object.keys(this.upgradeForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.upgradeForm[keys[n]] !== null) {
          formData.append(keys[n], this.upgradeForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post(
          `professional/clientserviceupgrade/${this.upgradeForm.id}?_method=PUT`,
          formData,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log(res.data);
          res;

          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getUpgrade();
          this.upgradeForm = {};
          $("#upgradeModel").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.upgradeForm.errors = error.response.data
        });
    },

    createCertificateByPrfesional() {
      this.certificateForm.client_service_id = this.project.id;
      this.certificateForm.service_id = this.project.service_id;
      // console.log(this.certificateForm);

      const formData = new FormData();
      const keys = Object.keys(this.certificateForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.certificateForm[keys[n]] !== null) {
          formData.append(keys[n], this.certificateForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("professional/clientservicecertificate", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          this.certificateForm = {};
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.LoadDocuments();
          $("#documentsByPro").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);

          this.certificateForm.errors = error.response.data
        });
    },
    updateCertificateByPrfesional() {
      this.certificateForm.client_service_id = this.project.id;
      this.certificateForm.service_id = this.project.service_id;
      // console.log(this.certificateForm);

      const formData = new FormData();
      const keys = Object.keys(this.certificateForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.certificateForm[keys[n]] !== null) {
          formData.append(keys[n], this.certificateForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post(`professional/clientservicecertificate/${this.certificateForm.id}?_method=PUT`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          this.certificateForm = {};
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.LoadDocuments();
          $("#documentsByPro").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);

          this.certificateForm.errors = error.response.data
        });
    },
    clientserviceDocumentLoad() {
      this.$axios
        .get(
          `professional/clientservicedocument?client_service_id=${this.$route.params.id}`,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log('-------clientservice_id--------')
          console.log(res.data.data.data);
          this.documentsByRetailerAll = res.data.data.data;
        });
    },
    uploadDocuments(event, docc) {
      this.uploadDocc_form.serviceDocument = event.target.files["0"];
      this.uploadDocc_form.service_document_id = docc.id;
      this.uploadDocc_form.client_service_id = this.$route.params.id;
      this.uploadDocc_form.alt = docc.document;
      this.uploadDocc_form.caption = docc.document;

      // console.log('-------------------------------------------------------------------------------')

      const formData = new FormData();
      const keys = Object.keys(this.uploadDocc_form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.uploadDocc_form[keys[n]] !== null) {
          formData.append(keys[n], this.uploadDocc_form[keys[n]]);
        }
      }
      this.$axios
        .post("retailer/clientservicedocument", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          // alert(res.data)
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "The form was sent successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          this.clientserviceDocumentLoad();
        });
    },
    LoadDocuments() {
      // var service_id = service.id;

      this.$axios
        .get(
          `professional/clientservicecertificate?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          //   console.log(res.data)
          this.allDoccs = res.data.data.data;
        });
    },
    finalSubmitChallan(){
     




        


        this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Submit it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, Submit it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var form = {}
      form.professional_id = this.userData.id
      form.client_service_id = this.$route.params.id


      this.loading = true;
      this.$axios
        .post(
          `professional/clientservicechallan/finalize`,
          form,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          console.log(res.data)
          toast.success("Final Submite successfully", {
                autoClose: 1000,
              });
          this.loadClientServiceProfesssional()
          
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // this.form.errors = error.response.data;
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            text: error.response.data.error,
          });
        })

          }
        });

    },

    loadUserApi() {
      this.$axios
        .post(
          `professional/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log(res.data)
          this.userData = res.data;
        });
    },
    downloadImg(link) {
      var url;
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/thumbs/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/thumbs/" +
          link.document;
      }
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/" +
          link.document;
      }
      // console.log('downloading : ', url);
      return url;
    },
    loadProject() {
      this.$axios
        .get(`professional/clientservice/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.project = res.data.data;
          this.loadModules();
        });
    },
    loadModules() {
     
 
      var mod = [];
      var srv = [];
      mod = JSON.parse(this.project.serviceModules);
      for (var i = 0; i < mod.length; i++) {
        var data = `&serviceModulesIds[]=${mod[i]}`;

        console.log(data)
   
        srv.push(data);
      }

      this.$axios
        .get(
          `professional/servicemodule?client_service_id=${this.$route.params.id}${srv}`,

          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.serviceModules = res.data.data.data;
        });
    },
    loadPageTitle(){
        document.title =this.$store.state.professionalPageTitles.clientsServiceView
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadProject();
      this.loadUserApi();
      this.getChalans();
      this.getUpgrade();
      this.LoadDocuments();
      this.loadTask();
      this.clientServiceDoccumentRequestGet();
      this.getAllComments();
      this.clientserviceDocumentLoad();
      this.progressForProject();
      this.professionaUser  = JSON.parse(localStorage.getItem("userData"));
      this.loadPageTitle()
      this.loadClientServiceProfesssional()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}


.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

h4 {
  font-size: 1rem;
}
</style>
